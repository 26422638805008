
import { Component, VModel, Vue } from "vue-property-decorator";

import * as FactcheckServices from "@/services/FactCheckService";
import { ROOT_ACTIONS } from "@/store/actions";

@Component
export default class DeleteFactcheckDialog extends Vue {
  @VModel({ required: true }) show!: boolean;

  loading = false;

  async deleteFactcheck() {
    try {
      this.loading = true;
      await FactcheckServices.DeleteFactcheck(this.$route.params.id);
      this.$router.push("/factchecks");
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "info",
        text: "Factcheck verwijderd",
      });
    } catch (error) {
      this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
        color: "error",
        text: error,
      });
    } finally {
      this.loading = false;
    }
  }
}
